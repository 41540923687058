export const LOAD_NEXT_PAGE = 'ACCOUNT_LOAD_NEXT_PAGE';
export const REQUEST_LIST = 'ACCOUNT_REQUEST_LIST';
export const REQUEST_LIST_SUCCEED = 'ACCOUNT_REQUEST_LIST_SUCCEED';
export const REQUEST_LIST_FAILED = 'ACCOUNT_REQUEST_LIST_FAILED';
export const CANCEL_LIST_REQUEST = 'ACCOUNT_CANCEL_LIST_REQUEST';
export const IGNORE_LIST_REQUEST = 'ACCOUNT_IGNORE_LIST_REQUEST';

export const UPDATE_FILTERS = 'ACCOUNT_UPDATE_FILTERS';
export const RESET_FILTERS = 'ACCOUNT_RESET_FILTERS';

export const UPDATE_SORT_ORDER = 'ACCOUNT_UPDATE_SORT_ORDER';

export const REFRESH_LIST = 'ACCOUNT_REFRESH_LIST';
export const REFRESH_LIST_SUCCEED = 'ACCOUNT_REFRESH_LIST_SUCCEED';
export const REFRESH_LIST_FAILED = 'ACCOUNT_REFRESH_LIST_FAILED';

export const REFERESH_PRODUCT_LIST = 'ACCOUNT_REFERESH_PRODUCT_LIST';
export const REFERESH_PRODUCT_LIST_SUCCEED = 'ACCOUNT_REFERESH_PRODUCT_LIST_SUCCEED';
export const REFERESH_PRODUCT_LIST_FAILED = 'ACCOUNT_REFERESH_PRODUCT_LIST_FAILED';

export const REFRESH_COUPONS_LIST = 'ACCOUNT_REFRESH_COUPONS_LIST';
export const REFRESH_COUPONS_LIST_SUCCEED = 'ACCOUNT_REFRESH_COUPONS_LIST_SUCCEED';
export const REFRESH_COUPONS_LIST_FAILED = 'ACCOUNT_REFRESH_COUPONS_LIST_FAILED';

/**
 * Loads next page based on lastRequest, filters & sorting criteria.
 * When previous request is in-progress, does not request to load data from server.
 */
export const loadNextPage = () => {
  return {
    type: LOAD_NEXT_PAGE,
  };
};

/**
 * Requests to get account list based on  lastRequest, filters & sorting criteria.
 * Updates `lastRequest.status` to `IN_PROGRESS`
 * @param {Boolean} nextPage When it's `true` requests for next page otherwise loads 1st page records.
 */
export const _requestList = (nextPage) => {
  return {
    type: REQUEST_LIST,
    nextPage,
  };
};

/**
 * Dispatched when list request succeed.
 * Updates result, lastRequest details.
 * @param {Array} data List of account
 * @param {Boolean} nextPage When `true`, appends data to current list otherwise overrides result with current data.
 * @returns
 */
export const _requestListSucceed = (data, nextPage) => {
  return {
    type: REQUEST_LIST_SUCCEED,
    data,
    nextPage,
  };
};

/**
 * Dispatched when list request failed with any reason.
 * Updates `lastRequest`'s status to `FAILED`.
 * @param {Object} error Error code
 */
export const _requestListFailed = (error) => {
  return {
    type: REQUEST_LIST_FAILED,
    error,
  };
};

/**
 * Cancels the last `in-progress` request.
 * @param {String} reason Reason to cancel the request. Possible values: 'UPDATE_FILTERS', 'UPDATE_SORT_ORDER', 'PAGE_CLOSED'
 */
export const _cancelListRequest = (reason) => {
  return {
    type: CANCEL_LIST_REQUEST,
    reason,
  };
};

/**
 * This action is just for notification into redux-devtools.
 * It's dispatched when previous request is already in-progress & view component requests to load next page.
 */
export const _ignoreListRequest = () => {
  return {
    type: IGNORE_LIST_REQUEST,
  };
};

/**
 * Requests to refresh account list based on lastRequest & sorting criteria.
 */
export const _refreshList = () => {
  return {
    type: REFRESH_LIST,
  };
};

/**
 * Dispatched when list refresh succeed.
 * Updates result details.
 * @param {Array} data List of account
 * @returns
 */
export const _refreshListSucceed = (data) => {
  return {
    type: REFRESH_LIST_SUCCEED,
    data,
  };
};

/**
 * Dispatched when list refresh failed with any reason.
 * @param {Object} error Error code
 */
export const _refreshListFailed = (error) => {
  return {
    type: REFRESH_LIST_FAILED,
    error,
  };
};

/**
 *
 * @param {Object} filters Filter criteria. e.g. { ownerName, ownerEmail, accountId, name, createdFrom, createdTo, lastAccessedFrom, lastAccessedTo, subscriptionExpireFrom, planInterval, subscriptionStatus, subscriptionExpireTo, plan, fileStore, autoRenew, pendingInvoices, offlinePaymentEnable }
 */
export const updateFilters = (filters) => {
  return {
    type: UPDATE_FILTERS,
    filters,
  };
};

/**
 * Resets to default filters.
 */
export const resetFilters = () => {
  return {
    type: RESET_FILTERS,
  };
};

/**
 * Updates sort criteria.
 * @param {String} sortBy Sort By field name. e.g. 'NAME' or 'OWNER_NAME' or 'OWNER_EMAIL' or 'PLAN' or 'CREATED_AT' or 'EXPIRE_AT' or 'ACTIVE_USERS' or 'PENDING_INVOICES' or 'QUOTA'
 * @param {String} sortOrder Sort order. e.g. 'ASC' or 'DESC'
 */
export const updateSortOrder = (sortBy, sortOrder) => {
  return {
    type: UPDATE_SORT_ORDER,
    sortBy,
    sortOrder,
  };
};

/**
 * Dispatched action to refresh product list.
 */
export const refershProductList = () => {
  return {
    type: REFERESH_PRODUCT_LIST,
  };
}

export const _refershProductListSucceed = (data) => {
  return {
    type: REFERESH_PRODUCT_LIST_SUCCEED,
    data,
  };
}

export const _refershProductListFailed = (error) => {
  return {
    type: REFERESH_PRODUCT_LIST_FAILED,
    error,
  };
}

/**
 * Dispatched action to refresh coupons list.
 */
export const refreshCouponsList = () => {
  return {
    type: REFRESH_COUPONS_LIST,
  };
}

export const _refreshCouponsListSucceed = (data) => {
  return {
    type: REFRESH_COUPONS_LIST_SUCCEED,
    data,
  };
}

export const _refreshCouponsListFailed = (error) => {
  return {
    type: REFRESH_COUPONS_LIST_FAILED,
    error,
  };
}