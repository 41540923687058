export const URLs = {
  pages: [
    {
      module: 'users',
      name: 'USERS',
      pathPattern: '/',
    },
    {
      module: 'users',
      name: 'USERS',
      pathPattern: '/users',
    },
    {
      module: 'accounts',
      name: 'ACCOUNTS',
      pathPattern: '/accounts',
    },
    {
      module: 'non-profit-domains',
      name: 'NON_PROFIT_DOMAINS',
      pathPattern: '/non-profit-domains',
    },
    {
      module: 'public-domains',
      name: 'PUBLIC_DOMAINS',
      pathPattern: '/public-domains'
    },
    {
      module: 'system-language',
      name: 'SYSTEM_LANGUAGE',
      pathPattern: '/system-language'
    },
    {
      module: 'malicious-url',
      name: 'MALICIOUS_URL',
      pathPattern: '/malicious-url'
    }
  ],

  dialogs: [
    {
      module: 'users',
      name: 'MANAGE_USER',
      pathPattern: '#manage-user/:userId',
      pathParams: {
        userId: String
      }
    },
    {
      module: 'accounts',
      name: 'MANAGE_ACCOUNT',
      pathPattern: '#manage-account/:accountId/:tab?',
      pathParams: {
        accountId: String,
        tab: String
      }
    }
  ],
};

export default URLs;
