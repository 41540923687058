import { takeEvery, select } from 'redux-saga/effects'
import { ROUTE_CHANGED } from '@dreamworld/router';
import * as routerSelectors from '../router/selectors.js';
/**
 * Load redux module based on pageName.
 */
function* loadModule() {
  const state = yield select();
  const pageName = routerSelectors.pageName(state);
  switch (pageName) {
    case 'USERS':
      import('../users/init.js');
      break;
    case 'ACCOUNTS':
      import('../accounts/init.js');
      break;
  }

  const dialogName = routerSelectors.dialongName(state);
  switch (dialogName) {
    case 'MANAGE_USER':
      import('../users/init.js');
      import('../accounts/init.js');
      break;
    case 'MANAGE_ACCOUNT':
      import('../manage-account/init.js');
      import('../accounts/init.js');
      break;
  }
}

/*
  Starts moduleLoader on each dispatched `ROUTER_ROUTE_CHANGED` action.
*/
function* moduleLoader() {
  yield takeEvery(ROUTE_CHANGED, loadModule);
}

export default moduleLoader;
