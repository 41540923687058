import { color } from '@dreamworld/material-styles/color.js';
import { ThemeStyle } from '@dreamworld/material-styles/theme.js';
import { Shadow } from '@dreamworld/material-styles/shadow.js';
import { css, unsafeCSS } from 'lit';

export const theme = css`
  ${unsafeCSS(color)}
  ${unsafeCSS(ThemeStyle)}
    ${unsafeCSS(Shadow)}
    :host {
    /* START: Theme variables */
    --mdc-theme-primary: #c11e5c;
    --mdc-theme-secondary: #2096f3;
    --mdc-theme-error: #ed1c24;
    --mdc-theme-background: #D8E3DF;
    --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.56);
    --mdc-theme-text-secondary-on-surface: rgba(0, 0, 0, 0.56);
    --secondary-text-color: var(--mdc-theme-text-secondary-on-surface);
    --mdc-theme-text-disabled: rgba(0, 0, 0, 0.38);
    --empty-label-text-color: var(--mdc-theme-text-disabled);
    --mdc-theme-text-hint: var(--mdc-theme-text-disabled);
    /* END: Theme vatiables */

    --dw-disabled-background-color: rgba(0, 0, 0, 0.12);
    --divider-color: rgba(0, 0, 0, .10);
    --dw-icon-color-active-on-light: #c11e5c;
    --dw-icon-color-on-light: rgba(0, 0, 0, .54);
    --dw-icon-color-disabled-on-light: rgba(0, 0, 0, .38);

    --dw-icon-color: var(--dw-icon-color-on-light);
    --dw-icon-color-active: var(--dw-icon-color-active-on-light);
    --dw-icon-color-disabled: var(--dw-icon-color-disabled-on-light);
    --edit-bill-address-hover-background-color: rgba(0, 0, 0, 0.04);
    --page-header-desktop-layout-height: 68px;
    --page-header-mobile-layout-height: 44px;
    --page-header-tablet-layout-height: 44px;
    --app-logo-color: #EC145B;
    --confirm-background-color: #fdf6eb;
    --item-highlight-color: #d7e8c6;
    --delete-warning-background-color: rgba(237, 28, 36, 0.24);
    --online-badge-background-color: #7cb342;
    --away-badge-background-color: rgb(243, 190, 8);
    --success-background-color: #00951B1F;
    --warning-background-color-variants: rgba(248, 152, 29, 0.12);
    --error-background-color: rgba(237, 28, 36, 0.12);
    --green-color: #00951B;
    --red-color-variants: #FF0000;
    --primary-background-color: #fff;
    /* END: Theme variables */

    /* Checked icon color.*/
    --check-icon-color: #7CB342;

    --dark-orange-color: #F8981D;

    --light-green-text-color: #0DA400;

     /* Typography */

    /* headline 5 */
    --headline5-font-size: 24px;
    --headline5-font-weight: 400;
    --headline5-letter-spacing: 0.25px;
    --headline5-line-height: normal;

    /* headline 6 */
    --headline6-font-size: 18px;
    --headline6-font-weight: 400;
    --headline6-letter-spacing: 0.25px;
    --headline6-line-height: normal;

    /* body 1 */
    --body1-font-size: 16px;
    --body1-font-weight: 400;
    --body1-letter-spacing: 0.5px;
    --body1-line-height: 18px;

    /* subtitle 1 */
    --subtitle1-font-size: var(--body1-font-size);
    --subtitle1-font-weight: var(--body1-font-weight);
    --subtitle1-letter-spacing: var(--body1-letter-spacing);
    --subtitle1-line-height: var(--body1-line-height);
    --subtitle1-text-transform: uppercase;

    /* subtitle 2 */
    --subtitle2-font-size: 14px;
    --subtitle2-font-weight: 500;
    --subtitle2-letter-spacing: 0.1px;
    --subtitle2-line-height: normal;

    /* body 2 */
    --body2-font-size: 14px;
    --body2-font-weight: 400;
    --body2-letter-spacing: 0.25px;
    --body2-line-height: 18px;

    /* button */
    --button-font-size: 14px;
    --button-font-weight: 400;
    --button-letter-spacing: 1.25px;
    --button-line-height: 14px;

    /* caption */
    --caption-font-size: 12px;
    --caption-font-weight: 400;
    --caption-letter-spacing: 0.25px;
    --caption-line-height: normal;

    /* overline */
    --overline-font-size: 10px;
    --overline-font-weight: 400;
    --overline-letter-spacing: 1.5px;
    --overline-line-height: 10px;
  }
`;

export default theme;
