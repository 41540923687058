import memoize from 'proxy-memoize';
import kebabCase from 'lodash-es/kebabCase';
import forEach from 'lodash-es/forEach';
import uniq from 'lodash-es/uniq';
import isEqual from 'lodash-es/isEqual';
import isFQDN from 'validator/es/lib/isFQDN';
import { createSelector } from 'reselect';

/**
 * Set focus to end of text
 * @param { Element } ele - Javascript element
 */
export const setFocusToEnd = (ele) => {
  if (typeof ele.selectionStart == 'number') {
    ele.selectionStart = ele.selectionEnd = ele.value.length;
    ele.focus();
  } else if (typeof ele.createTextRange != 'undefined') {
    ele.focus();
    let range = ele.createTextRange();
    range.collapse(false);
    range.select();
  }
};

/**
 * Validates domain name.
 * @param {string} domain Domain name
 */
export const validateDomain = (domain, allow_wildcard = false) => {
  if(allow_wildcard) {
    return /^[\w*]+(\.[\w*]+)+$/.test(domain) || false;
  }
  return isFQDN(String(domain), { allow_wildcard });
};


/**
 * Validates email address.
 * @param {String} email Email address
 */
export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * @param {String} cname - Give cookie name to return a cookie value
 * @returns {String} cookie value.
 */
export const getCookieVal = (cname) => {
  if (!cname) {
    return;
  }

  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return;
};

/**
 * @param{}
 *  @property {String} id Entity Id.
 *  @property {String} prefix Prefix which should be removed.
 * @returns {String} uuid without prefix.
 */
export const getIdWoPrefix = memoize(({ id = '', prefix }) => {
  return (id && id.replace(`${prefix}`, '')) || id;
});


/**
 * Downloads Given URL imperatively.
 * @param {String} url Url to be download
 */
 export const downloadUrl = (url) => {
  let a = document.createElement("a");
  a.setAttribute('download', "");
  a.setAttribute('target', '_blank');
  a.setAttribute('href', url);
  const b = document.body;

  b.appendChild(a);
  setTimeout(() => {
    a.click();
    b.removeChild(a);
  })
}

/**
 * Encodes a JavaScript object into a string which can be passed via a GET request.
 * @param {Object} params
 * @returns
 */
export const serializeQueryParams = (params) => {
  let str = [];
  for (let p in params)
    if (params.hasOwnProperty(p)) {
      str.push(kebabCase(p) + "=" + params[p]);
    }
  return str.join("&");
}

/**
 * @param {Object} user
 * @return {String} user full name.
 * @protected
 */
export const getUserName = (user) => {
  if (!user) {
    return '';
  }

  if (!user.name && !user.firstName && !user.lastName) {
    return '';
  }

  let firstName = user.name || user.firstName;
  let sFullName = firstName || '';
  if (!user.name && user.lastName) {
    sFullName = sFullName + ' ' + user.lastName;
  }
  return sFullName;
}

/**
 * @param {Number} amount
 * @returns {String} formated amount, e.g. 24.00
 */
export const amountFormat = (amount) => {
  return amount ? (amount / 100).toFixed(2) : '0.00';
}

/**
 * @param {String} userIds
 * @returns {Array} user uniq ids Array
 * e.g.
 *  - input: usr_6ZtXcP8Blkg25dpd6YMYHY,usr_1ju5cSftbvQIqL6KqdbMeq,usr_1ju5cSftbvQIqL6KqdbMeq,usr_1ju5cSftbvQIqL6KqdbMeq,usr_2WPpMyS8brfJmtQcRTKerZ
 *  - output: ['usr_6ZtXcP8Blkg25dpd6YMYHY','usr_1ju5cSftbvQIqL6KqdbMeq','usr_2WPpMyS8brfJmtQcRTKerZ']
 *
 *  - input: usr_6ZtXcP8Blkg25dpd6YMYHY,usr_1ju5cSftbvQIqL6KqdbMeq usr_1ju5cSftbvQIqL6KqdbMeq,usr_1ju5cSftbvQIqL6KqdbMeq usr_2WPpMyS8brfJmtQcRTKerZ
 *  - output: ['usr_6ZtXcP8Blkg25dpd6YMYHY','usr_1ju5cSftbvQIqL6KqdbMeq','usr_2WPpMyS8brfJmtQcRTKerZ']
 *
 *  - input:   usr_6ZtXcP8Blkg25dpd6YMYHY   ,  usr_1ju5cSftbvQIqL6KqdbMeq usr_1ju5cSftbvQIqL6KqdbMeq,  usr_1ju5cSftbvQIqL6KqdbMeq usr_2WPpMyS8brfJmtQcRTKerZ
 *  - output: ['usr_6ZtXcP8Blkg25dpd6YMYHY','usr_1ju5cSftbvQIqL6KqdbMeq','usr_2WPpMyS8brfJmtQcRTKerZ']
 */
export const getUserIds = function(userIds){
  if(!userIds) {
    return [];
  }
  const userIdsArray = [];
  const spaceSparater = userIds.split(' ');
    forEach(spaceSparater, (item1)=> {
    const commaSparater = item1.split(',');
      forEach(commaSparater, (item2) => {
      item2 = item2 && item2.trim() || '';
      if(item2) {
        userIdsArray.push(item2);
      }
    });
  });
  return uniq(userIdsArray);
}

const _regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
const getRegionNames = () => {
  return _regionNames;
}

/**
 * @returns {String} country name based on given code.
 */ 
export const getCountryName = createSelector(
  (code) => code,
  (code) => getRegionNames(),
  (code, regionNames) => {
    try {
      return regionNames.of(code);
    } catch (error) {
      return '';
    }
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 300,
    },
  }
);

/**
 * Returns the currency symbol for a given currency code.
 * Uses the browser's Intl API to get localized currency symbols.
 * 
 * @param {string} currencyCode - The ISO 4217 currency code (e.g., 'USD', 'EUR')
 * @param {string} [locale='en-US'] - The locale to use for formatting
 * @returns {string} The currency symbol or the original currency code if not found
 */
export const getCurrencySymbol = (currencyCode, locale = 'en-US') => {
  if (!currencyCode) return '';

  currencyCode = currencyCode.toUpperCase();
  try {
    // Create a formatter with the specified currency
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    
    // Format a 0 value and extract just the symbol
    const parts = formatter.formatToParts(0);
    const currencySymbol = parts.find(part => part.type === 'currency')?.value || currencyCode;
    
    return currencySymbol;
  } catch (error) {
    // If the currency code is invalid, return the original code
    return currencyCode;
  }
};